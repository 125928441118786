import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { FC } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

import { Layout } from "../shared/components/Layout";

const RICHTEXT_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: (text: any) => <span className="font-extrabold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return <p style={{ marginBottom: 10 }}>{children}</p>;
    },
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        className="text-blue-700 underline"
        href={data.uri}
        target={`${data.uri.startsWith("salihusain") ? "_self" : "_blank"}`}
        rel={`${
          data.uri.startsWith("salihusain") ? "" : "noopener noreferrer"
        }`}
      >
        {children}
      </a>
    ),
  },
};

export const query = graphql`
  query ($id: String!) {
    contentfulBlog(contentful_id: { eq: $id }) {
      author
      contentful_id
      excerpt {
        excerpt
      }
      body {
        raw
      }
      slug
      title
      updatedAt(formatString: "LL")
      blogImage {
        gatsbyImageData
      }
    }
  }
`;

interface Props {
  data: any;
  location: PageProps["location"];
}

const Blog: FC<Props> = ({ location, data }) => {
  const { title, body, updatedAt, slug, author, excerpt, blogImage } =
    data?.contentfulBlog;

  const json = JSON.parse(body.raw);

  return (
    <Layout
      seo={{
        url: `https://syedalihusain.netlify.app/blog/${slug}`,
        description: excerpt.excerpt,
        title: `${author} | ${updatedAt}`,
        keywords: [
          title,
          author,
          "Kidney",
          "Medicine",
          "MD",
          "Doctor",
          "Nephrology",
          "Nephrologist",
          "Blog",
        ],
      }}
      location={location}
    >
      <div className="bg-gray-50 overflow-hidden min-full-screen">
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div>
              <h2 className="text-base text-gray-500 font-semibold tracking-wide uppercase">
                {updatedAt}
              </h2>
              <h2 className="text-blue-900 font-semibold text-lg tracking-wide">
                {author}
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <GatsbyImage
                      className="rounded-lg shadow-sm object-cover object-center z-10"
                      image={blogImage.gatsbyImageData}
                      alt=""
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <div className="text-lg text-gray-500">
                  {documentToReactComponents(json, RICHTEXT_OPTIONS)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
